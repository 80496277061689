.spinner {
    --gap: 10px;
    --clr: #32A55C;
    --height: 30px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--gap);
   }
   
   .spinner span {
    background: #32A55C;
    width: 20px;
    height: var(--height);
    animation: grow 1s ease-in-out infinite;
   }
   
   .spinner span:nth-child(2) {
    animation: grow 1s ease-in-out 0.15s infinite;
   }
   
   .spinner span:nth-child(3) {
    animation: grow 1s ease-in-out 0.3s infinite;
   }
   
   .spinner span:nth-child(4) {
    animation: grow 1s ease-in-out 0.475s infinite;
   }
   
   @keyframes grow {
    0%,100% {
        transform: scaleY(1);
        background-color: #32A55C;
    /* background: linear-gradient(306.38deg, #0B6329 15.8%, #32A55C 100.28%); */
    }
    
    50% {
        transform: scaleY(1.8);
        background-color: #0B6329;
    /* background: linear-gradient(306.38deg, #0B6329 15.8%, #32A55C 100.28%); */
    }
   }